@charset "utf-8" !important;

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #d86018 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #d86018;
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

body {
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 300 !important;
  line-height: 1.4 !important;
  color: #333f48 !important;
  height: 100% !important;
  background: #ffffff !important;
}

/* -------- Text ------------ */
h1,
.h1 {
  position: relative !important;
  font-size: 60px !important;
  line-height: 1 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  color: #d86018 !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
}

h2,
.h2 {
  position: relative !important;
  font-size: 36px !important;
  line-height: 1 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  color: #d86018 !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
}

h3,
.h3 {
  position: relative !important;
  font-size: 34px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  margin: 16px 0 !important;
  letter-spacing: -0.02em !important;
  text-transform: none !important;
}

h4,
.h4 {
  position: relative !important;
  font-size: 24px !important;
  line-height: 1 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
}

h5,
.h5 {
  position: relative !important;
  font-size: 24px !important;
  line-height: 1.4 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  text-transform: none !important;
}

h6,
.h6 {
  position: relative !important;
  font-size: 17px !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
  margin: 16px 0 !important;
  text-transform: none !important;
}

p {
  position: relative !important;
  font-size: 17px !important;
  line-height: 1.4 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  text-transform: none !important;
}

a {
  color: #d86018 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

b,
strong {
  font-weight: 700 !important;
}

h1 strong,
.h1 strong,
h1 b,
.h1 b,
h2 strong,
.h2 strong,
h2 b,
.h2 b {
  color: #333f48 !important;
  font-weight: 700 !important;
}

.text-white h1 strong,
.text-white .h1 strong,
.text-white h1 b,
.text-white .h1 b,
.text-white h2 strong,
.text-white .h2 strong,
.text-white h2 b,
.text-white .h2 b {
  color: #ffffff !important;
}

hr {
  border: none !important;
  border-bottom: 1px solid #a3bcbd !important;
  margin: 32px 0 !important;
  clear: both !important;
}

blockquote {
  position: relative !important;
  display: block !important;
  box-sizing: border-box !important;
  background: none !important;
  font-size: 34px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  color: #afe0ea !important;
  padding: 1em !important;
  margin: 16px 0 !important;
  max-width: 14.5em !important;
}

blockquote * {
  margin: 0 !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #ffffff !important;
}

.orange {
  color: #d86018 !important;
}

.grey {
  color: #333f48 !important;
}

.inline {
  display: inline-block !important;
  vertical-align: middle !important;
  margin-right: 0.1em !important;
}

.float-right {
  position: relative !important;
  float: right !important;
  margin-top: 0 !important;
  z-index: 1 !important;
}

.small {
  display: block !important;
  font-size: 14px !important;
  line-height: 1.4 !important;
  font-weight: 300 !important;
  margin: 16px 0 0 0 !important;
}

.welcome {
  text-transform: none !important;
  line-height: 1.4 !important;
  letter-spacing: 0 !important;
  color: #999fa3 !important;
  text-align: center !important;
  margin-bottom: 3.5% !important;
}

.welcome:after {
  content: "" !important;
  display: block !important;
  max-width: 130px !important;
  margin: 16px auto !important;
  border-top: 1px solid currentColor !important;
}

/* -------- Icons ------------ */
.close,
.checkbox input[type="checkbox"]:checked+label:before,
.number button:before,
.btn-reset:before,
.btn__header:before,
.btn-save:before,
.btn-continue:after,
.btn-back:before,
.btn-calculator:before,
.btn-add:before,
.expand-header p:after {
  background-image: url(images/icons.png) !important;
  background-image: url(images/icons.svg),
    linear-gradient(transparent, transparent), none !important;
  background-repeat: no-repeat !important;
}

/* -------- Lists ------------ */
.bodytext ul {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
}

.bodytext ul li {
  display: inline-block !important;
  vertical-align: top !important;
  position: relative !important;
  list-style: none !important;
  width: 100% !important;
  padding: 0.3em 0 0.3em 1.8em !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  text-align: left !important;
  font-size: 17px !important;
}

.bodytext ul li:before {
  content: "" !important;
  display: block !important;
  width: 0.6em !important;
  height: 0.6em !important;
  position: absolute !important;
  left: 0 !important;
  margin-top: 0.5em !important;
  background: #d86018 !important;
  border-radius: 50% !important;
}

.bodytext ol {
  position: relative !important;
  list-style: none !important;
  counter-reset: ol !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
}

.bodytext ol li {
  display: inline-block !important;
  vertical-align: top !important;
  list-style: none !important;
  counter-increment: ol !important;
  width: 100% !important;
  position: relative !important;
  padding: 0.3em 0 0.3em 1.8em !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  font-size: 17px !important;
}

.bodytext ol li:before {
  content: counter(ol) !important;
  position: absolute !important;
  left: 0 !important;
  font-weight: 800 !important;
  color: #d86018 !important;
  text-align: center !important;
}

.bodytext li>*:first-child {
  margin-top: 0 !important;
}

.text-white .bodytext li {
  color: #ffffff !important;
}

/* -------- Tables ------------ */
table {
  width: 100% !important;
  margin: 16px 0 !important;
  border-spacing: 0 !important;
  font-size: 17px !important;
  line-height: 1.4 !important;
  font-weight: 400 !important;
  border-bottom: 1px solid #487a7b !important;
}

th {
  padding: 17px 16px !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #487a7b !important;
}

td {
  padding: 17px 16px !important;
  border-top: 1px solid #edf2f2 !important;
  background: #ffffff !important;
  word-break: break-word !important;
}

td:nth-child(even) {
  background: #fbfbfb !important;
}

.harv-settings td,
.harv-settings th {
  text-align: right !important;
}

.harv-settings td:first-child {
  text-align: left !important;
  font-weight: 700 !important;
}

.harv-settings td:last-child {
  background: #dae4e5 !important;
}

.scroll-h {
  overflow-x: auto !important;
}

.scroll-h td,
.scroll-h th {
  min-width: 120px !important;
  box-sizing: border-box !important;
}

/* -------- Forms ------------ */
form {
  margin: 0 !important;
}

fieldset {
  position: relative !important;
  z-index: 0 !important;
  display: block !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
  border: none !important;
  padding: 0 !important;
  margin: 26px 0 50px 0 !important;
  font-size: 0 !important;
  padding-left: 40px !important;
  border-left: 1px solid #a3bcbd !important;
  box-sizing: border-box !important;
}

fieldset>* {
  flex: 0 0 100% !important;
}

fieldset>fieldset {
  flex: 1 0 50% !important;
  max-width: 50% !important;
}

fieldset:nth-of-type(odd),
fieldset.bg-dark {
  background: #edf2f2 !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  padding-right: 100px !important;
  margin-right: -100px !important;
}

fieldset fieldset,
fieldset:nth-of-type(odd) fieldset {
  padding: 0 40px 0 0 !important;
  margin: 0 !important;
  border: none !important;
}

fieldset fieldset:nth-of-type(even) {
  padding: 0 0 40px 40px !important;
  margin: 0 !important;
  border: none !important;
}

fieldset fieldset:last-of-type {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

fieldset fieldset:nth-of-type(even):after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  z-index: -1 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: 0 -100px 0 0 !important;
  background: #dae4e5 !important;
}

fieldset fieldset:nth-of-type(2):after {
  margin-top: -40px !important;
}

fieldset fieldset:last-of-type:after {
  margin-bottom: -40px !important;
}

fielset .columns-wrap:last-child,
fieldset .columns-wrap:last-child {
  margin-bottom: -16px !important;
}

legend {
  position: relative !important;
  display: block !important;
  font-size: 24px !important;
  line-height: 1 !important;
  font-weight: 300 !important;
  margin: 16px 0 !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
}

label {
  display: block !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  margin: 8px 0 !important;
  box-sizing: border-box !important;
}

label.label-ontop {
  position: absolute !important;
  font-size: 12px !important;
  padding: 0 12px !important;
  margin: 0 !important;
  color: #999fa3 !important;
  pointer-events: none !important;
  background: none !important;
}

label.label-ontop.label-full {
  font-size: 17px !important;
  padding: 17px 20px !important;
}

.text-white legend,
.text-white label {
  color: #ffffff !important;
}

.text-white label.label-ontop {
  color: #999fa3 !important;
}

label.required:after {
  content: " *" !important;
  color: #8c1212 !important;
}

fieldset label {
  padding: 17px 12px !important;
  margin: 0 !important;
}

label .small {
  margin: 0 !important;
}

h2 {
  position: relative !important;
  padding-left: 1.11em !important;
}

h2:before {
  content: "" !important;
  display: block !important;
  width: 0.72em !important;
  height: 0.72em !important;
  background: #333f48 !important;
  position: absolute !important;
  left: 0 !important;
  top: 0.12em !important;
}

input,
textarea,
select,
option {
  width: 100% !important;
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  color: #222c34 !important;
  padding: 17px 12px !important;
  box-sizing: border-box !important;
  border: 1px solid #a3bcbd !important;
  background: #f6f8f8 !important;
}

select {
  padding-right: 50px !important;
  height: 60px !important;
  font-weight: 400 !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  background-image: url(images/icon-select.png) !important;
  background-image: url(images/icon-select.svg),
    linear-gradient(transparent, transparent), none !important;
  background-size: auto 100% !important;
  cursor: pointer !important;
}

select::-ms-expand {
  display: none !important;
}

option {
  border: none !important;
  border-radius: 0 !important;
}

select[multiple] {
  height: auto !important;
  background-image: none !important;
  padding: 0 !important;
}

textarea {
  resize: vertical !important;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto !important;
}

.label-ontop+input,
.label-ontop+select,
.label-ontop textarea {
  padding-top: 22px !important;
  padding-bottom: 12px !important;
}

.label-inline>input,
.label-inline>select,
.label-inline>div,
.number-input {
  width: 280px !important;
  max-width: 280px !important;
  flex: 1 0 280px !important;
  margin: 0 0 0 auto !important;
}

.label-inline label {
  flex: 1 1 auto !important;
  box-sizing: border-box !important;
}

.fieldwrap {
  position: relative !important;
  padding: 0 !important;
  margin: 16px 0 !important;
  box-sizing: border-box !important;
}

.fieldwrap.inline {
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 10px !important;
  flex-basis: auto !important;
}

.fieldwrap.label-inline {
  display: flex !important;
  align-items: flex-start !important;
  min-width: 280 !important;
  flex-grow: 1 !important;
  max-width: none !important;
}

fieldset .fieldwrap {
  background: #fbfbfb !important;
  margin: 0 0 16px 0 !important;
}

fieldset>.fieldwrap:last-child {
  margin: 0 !important;
}

fieldset:nth-of-type(odd) .fieldwrap {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

fieldset.bg-dark .fieldwrap {
  background: rgba(255, 255, 255, 0.05) !important;
}

.fieldwrap-confirm {
  border: 1px solid #edf2f2 !important;
}

.fieldwrap-confirm input[type="text"] {
  width: 60% !important;
}

.fieldwrap-confirm button {
  width: 30% !important;
  align-items: center;
}

.radio,
.checkbox {
  display: inline-block !important;
  vertical-align: top !important;
}

.radio label,
.checkbox label {
  display: inline-block !important;
}

.checkbox,
.radio {
  position: relative !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin: 10px 16px !important;
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 0 !important;
  font-size: 40px !important;
  width: 1em !important;
  height: 1em !important;
  margin: 0 !important;
}

.checkbox input[type="checkbox"]+label,
.radio input[type="radio"]+label {
  display: block !important;
  min-height: 40px !important;
  position: relative !important;
  padding: 7px 0 7px 50px !important;
  margin: 0 !important;
}

.checkbox input[type="checkbox"]+label:before,
.radio input[type="radio"]+label:before {
  content: "" !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  position: absolute !important;
  font-size: 40px !important;
  width: 1em !important;
  height: 1em !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background: #f6f8f8 !important;
  background: #fdfdfe !important;
  border: 1px solid #a3bcbd !important;
  cursor: pointer !important;
}

.radio input[type="radio"]+label:before {
  background: #f6f8f8 !important;
  border-radius: 30px !important;
  border: 1px solid #a3bcbd !important;
}

.checkbox input[type="checkbox"]:checked+label:before {
  background-position: -13.25em -5.75em !important;
  background-size: 15em auto !important;
}

.radio input[type="radio"]:checked+label:before {
  background-image: -webkit-radial-gradient(circle,
      #d86018 36%,
      transparent 40%) !important;
  background-image: radial-gradient(circle,
      #d86018 36%,
      transparent 40%) !important;
}

.powermail_fieldwrap_type_radio .radio {
  display: inline-block !important;
  vertical-align: top !important;
}

.using-tab .checkbox input[type="checkbox"]:focus+label:before,
/* .using-tab .radio input[type="radio"]:focus + label:before {box-shadow:inset 0 0 0 2px #D86018!important;} */

.toggle {
  position: relative !important;
  display: block !important;
  padding: 0 12px !important;
  margin: 0 16px !important;
  box-sizing: border-box !important;
}

.toggle input[type="checkbox"] {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  opacity: 0 !important;
  font-size: 30px !important;
  width: 2em !important;
  height: 1em !important;
  margin: 15px 0 !important;
}

.toggle input[type="checkbox"]+label {
  display: block !important;
  position: relative !important;
  padding: 17px 70px 17px 0 !important;
  margin: 0 !important;
}

.toggle input[type="checkbox"]+label:before {
  content: "" !important;
  display: block !important;
  top: 0 !important;
  right: 0 !important;
  position: absolute !important;
  font-size: 30px !important;
  width: 2em !important;
  height: 1em !important;
  border-radius: 0.5em !important;
  margin: 15px 0 !important;
  box-sizing: border-box !important;
  background: #999fa3 !important;
  cursor: pointer !important;
  transition: background 0.3s !important;
}

.toggle input[type="checkbox"]+label:after {
  content: "" !important;
  display: block !important;
  top: 0.13em !important;
  right: 1.13em !important;
  position: absolute !important;
  font-size: 30px !important;
  width: 0.74em !important;
  height: 0.74em !important;
  border-radius: 0.5em !important;
  margin: 15px 0 !important;
  box-sizing: border-box !important;
  background: #ffffff !important;
  cursor: pointer !important;
  transition: right 0.3s !important;
}

.toggle input[type="checkbox"]:checked+label:before {
  background-color: #7a9a01 !important;
}

.toggle input[type="checkbox"]:checked+label:after {
  right: 0.13em !important;
}

/* .using-tab .toggle input[type="checkbox"]:focus + label:before {box-shadow:inset 0 0 0 2px #D86018!important;} */
.fieldwrap.label-inline .toggle {
  width: auto !important;
  max-width: none !important;
  flex-basis: 100% !important;
}

.number {
  position: relative !important;
  padding: 0 60px !important;
  box-sizing: border-box !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
  text-align: right !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.number {
  position: relative !important;
}

.number input {
  border-left: none !important;
  border-right: none !important;
}

.number button {
  position: absolute !important;
  overflow: hidden !important;
  bottom: 0 !important;
  margin: 0 !important;
  display: block !important;
  font-size: 60px !important;
  width: 1em !important;
  height: 1em !important;
  box-sizing: border-box !important;
  background: #a3bcbd !important;
  transition: background 0.3s !important;
}

.number button:before {
  content: "" !important;
  display: block !important;
  width: 1em !important;
  height: 1em !important;
  background-size: 10em auto !important;
  background-position: -0.33em -12em !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  margin: -0.5em !important;
}

.number button.number-add:before {
  background-position: -2em -12em !important;
}

.number button:hover {
  background: #91afb0 !important;
}

.number-remove {
  left: 0 !important;
}

.number-add {
  right: 0 !important;
}

[data-pre]:before,
[data-post]:after {
  content: attr(data-pre) !important;
  display: block !important;
  position: absolute !important;
  left: 60px !important;
  bottom: 0 !important;
  overflow: hidden !important;
  font-size: 17px !important;
  width: 60px !important;
  height: 58px !important;
  line-height: 58px !important;
  font-weight: 400 !important;
  margin: 1px 0 !important;
  text-align: center !important;
  background: #e4ebeb !important;
}

[data-post]:after {
  content: attr(data-post) !important;
  left: auto !important;
  right: 60px !important;
}

[data-pre] input {
  padding-left: 70px !important;
}

[data-post] input {
  padding-right: 70px !important;
}

fieldset.bg-dark {
  background: #333f48 !important;
}

fieldset:nth-of-type(2n + 1).bg-dark {
  background: #333f48 !important;
}

.reset {
  position: relative !important;
  padding: 0 60px 0 0 !important;
  box-sizing: border-box !important;
}

.reset[data-pre]:before {
  left: 1px !important;
}

.reset[data-post]:after {
  right: 1px !important;
}

.btn-reset {
  position: absolute !important;
  overflow: hidden !important;
  right: 0 !important;
  bottom: 0 !important;
  margin: 0 !important;
  display: block !important;
  font-size: 60px !important;
  width: 1em !important;
  height: 1em !important;
  box-sizing: border-box !important;
  background: #a3bcbd !important;
  transition: background 0.3s !important;
}

.btn-reset:before {
  content: "" !important;
  display: block !important;
  width: 1em !important;
  height: 1em !important;
  background-size: 10em auto !important;
  background-position: -3.66em -8.66em !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  margin: -0.5em !important;
}

.btn-reset:hover {
  background: #91afb0 !important;
}

/* -------- Body ------------ */
#body {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  margin: 0 auto !important;
  height: 100% !important;
  min-height: 100% !important;
  min-width: 320px !important;
  position: relative !important;
  text-align: left !important;
}

.body-cell {
  display: table-cell !important;
  position: relative !important;
}

* {
  font-family: aller !important;
  /* Add !important to overwrite all elements */
}

/* -------- Header ------------*/
#header {
  display: table-row !important;
}

#header .body-cell {
  position: relative !important;
  z-index: 100 !important;
  height: 180px !important;
  background: #edf2f2 !important;
}

#header .content-padding {
  display: table !important;
  width: 100% !important;
  height: 180px !important;
  padding-right: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background: #edf2f2 url(images/bg-header.svg) no-repeat 64% top !important;
}

.header-logo {
  position: relative !important;
  z-index: 110 !important;
  display: table-cell !important;
  vertical-align: top !important;
  width: 170px !important;
}

.header-logo a {
  display: block !important;
  padding: 10px !important;
  margin: 0 0 0 0 !important;
  background: #ffffff !important;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1) !important;
}

.header-logo img {
  display: block !important;
}

.header-title {
  display: table-cell !important;
  vertical-align: middle !important;
  padding: 20px 2% !important;
}

.header-title span {
  position: relative !important;
  display: inline-block !important;
  vertical-align: top !important;
  font-size: 36px !important;
  line-height: 1 !important;
  border-left: 0.27em solid #d86018 !important;
  padding-left: 0.33em !important;
  text-transform: uppercase !important;
}

.header-buttons {
  display: table-cell !important;
  vertical-align: bottom !important;
  white-space: nowrap !important;
  font-size: 0 !important;
  text-align: right !important;
  padding: 15px 20px !important;
}

.header-buttons-left {
  display: table-cell !important;
  vertical-align: bottom !important;
  white-space: nowrap !important;
  font-size: 0 !important;
  text-align: left !important;
  padding: 15px 20px !important;
  padding-top: 20px !important;
}

.btn__header {
  position: relative !important;
  display: inline-block !important;
  vertical-align: top !important;
  font-size: 60px !important;
  width: 1em !important;
  height: 1em !important;
  border-radius: 50% !important;
  margin: 5px !important;
  background: #dae4e5 !important;
  transition: background 0.3s !important;
}

.btn__header:hover {
  background: #c8d7d7 !important;
}

.btn__header:before {
  content: "" !important;
  display: block !important;
  width: 1em !important;
  height: 1em !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  margin: -0.5em !important;
  background-size: 10em auto !important;
  background-position: -0.33em -0.33em !important;
}

.btn-annual:before {
  background-position: -3.66em -0.33em !important;
}

.btn-infield:before {
  background-position: -5.33em -10.33em !important;
}

.btn-machinery:before {
  background-position: -5.33em -0.33em !important;
}

.btn-about:before {
  background-position: -5.33em -0.33em !important;
}

.btn-contract:before {
  background-position: -7em -0.33em !important;
}

.btn-info:before {
  background-position: -8.66em -0.33em !important;
}

.btn-menu:before {
  background-position: -0.33em -0.33em;
}

.btn-farm:before {
  background-position: -5.33em -10.33em;
}

.btn-home {
  background-color: #d86018 !important;
}

.btn-home:hover {
  background: #f96921 !important;
}

.btn-home:before {
  background-position: -3.66em -12em !important;
}

select.header-season {
  display: inline-block !important;
  vertical-align: top !important;
  width: auto !important;
  height: 60px !important;
  line-height: 60px !important;
  padding: 0 16px 0 50px !important;
  border: none !important;
  margin: 5px !important;
  border-radius: 30px !important;
  background: #dae4e5 url(images/icon-date.svg) no-repeat left top !important;
  background-size: auto 100% !important;
}

.btn-menu {
  display: none !important;
}

.header-menu {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.profile__menu {
  position: absolute !important;
  z-index: 100 !important;
  right: 0 !important;
  top: 100% !important;
  width: 280px !important;
  background: #ffffff !important;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1) !important;
  opacity: 0 !important;
  visibility: hidden !important;
  transform: translateY(-20px) !important;
  transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s !important;
}

.profile__menu.open {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(0) !important;
  transition: opacity 0.3s, visibility 0s, transform 0.3s !important;
}

.profile__menu .profile__header {
  position: relative !important;
  background: #edf2f2 !important;
  padding-left: 90px !important;
}

.profile__header span {
  display: block !important;
}

.profile__menu a {
  display: block !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  color: #222c34 !important;
  padding: 18px 20px !important;
  transition: background 0.3s !important;
  border-top: 1px solid #edf2f2 !important;
}

.profile__menu a:hover {
  text-decoration: none !important;
  background: #f6f8f8 !important;
}

.profile__icon {
  display: inline-block !important;
  vertical-align: top !important;
  font-size: 24px !important;
  line-height: 2.5 !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #ffffff !important;
  width: 2.5em !important;
  height: 2.5em !important;
  margin: 5px !important;
  border-radius: 50% !important;
  background: #487a7b !important;
}

.profile__icon:hover {
  text-decoration: none !important;
  filter: brightness(1.1) !important;
}

.profile__menu .profile__icon {
  position: absolute !important;
  left: 16px !important;
  top: 50% !important;
  margin: -1.25em 0 !important;
}

.profile__name {
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.profile__view {
  font-size: 0.8em !important;
  font-weight: 300 !important;
  color: #858c91 !important;
}

/* -------- Content ------------ */
#body {
  display: table !important;
  width: 100% !important;
  height: 100% !important;
}

#content {
  display: table-row !important;
}

.body-cell {
  display: table-cell !important;
}

.clear {
  clear: both !important;
}

.hidden {
  display: none !important;
}

iframe,
video {
  max-width: 100% !important;
  border: none !important;
}

img,
svg {
  max-width: 100% !important;
  height: auto !important;
  vertical-align: bottom !important;
}

a img {
  border: none !important;
}

.content-1920 {
  position: relative !important;
  max-width: 1920px !important;
  margin: 0 auto !important;
}

.content-large {
  position: relative !important;
  max-width: 1600px !important;
  margin: 0 auto !important;
}

.content-medium {
  position: relative !important;
  max-width: 1300px !important;
  margin: 0 auto !important;
}

.content-small {
  position: relative !important;
  max-width: 960px !important;
  margin: 0 auto !important;
}

.content-padding {
  padding: 0 100px !important;
  margin: 100px 0 !important;
}

.content-padding.margin-top-none {
  margin-top: 0 !important;
}

.content-padding.margin-bottom-none {
  margin-bottom: 0 !important;
}

.content-padding.margin-top-small {
  margin-top: 50px !important;
}

.content-padding.margin-bottom-small {
  margin-bottom: 50px !important;
}

.scrollbar {
  scrollbar-width: thin !important;
  scrollbar-color: #0c2340 rgba(0, 0, 0, 0.05) !important;
}

.scrollbar::-webkit-scrollbar {
  width: 8px !important;
}

.scrollbar::-webkit-scrollbar-track {
  width: 8px !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #0c2340 !important;
}

.sticky {
  position: sticky !important;
  z-index: 10 !important;
  top: 0 !important;
  padding-top: 0.1px !important;
  padding-bottom: 0.1px !important;
}

.columns-wrap {
  position: relative !important;
}

.columns2,
.columns3 {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 0 -40px !important;
  font-size: 0 !important;
}

.columns3 {
  margin: 0 -20px !important;
}

.columns2>div,
.columns3>div {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  max-width: 50% !important;
  flex: 0 0 50% !important;
  border: 40px solid transparent !important;
  border-width: 0 40px !important;
  box-sizing: border-box !important;
  font-size: 1rem !important;
  background-clip: padding-box !important;
}

.columns3>div {
  width: 33.33% !important;
  max-width: 33.33% !important;
  flex: 0 0 33.33% !important;
  border-width: 0 20px !important;
}

.bg-dark {
  background: #333f48 !important;
  padding-top: 0.1px !important;
  padding-bottom: 0.1px !important;
}

.bg-overlap:after {
  content: "" !important;
  display: block !important;
  width: 100% !important;
  padding-bottom: 130px !important;
  position: absolute !important;
  z-index: -1 !important;
}

.bg-dark.bg-overlap:after {
  background: #333f48 !important;
}

.bg-light {
  background: #fbfbfb !important;
  padding: 0.1px 0 !important;
}

.alerts {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
}

.alert {
  background: #def2d6 !important;
  border-radius: 6px !important;
  color: #5a7052 !important;
  font-weight: 700 !important;
  padding: 10px !important;
  margin: 16px !important;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2) !important;
}

.alert-success {}

.alert-error {
  background: #ecc8c5 !important;
  color: #b32f2d !important;
}

/* -------- Buttons ------------ */
button,
input[type="submit"],
input[type="file"]::file-selector-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none !important;
  font-family: inherit !important;
  font-size: 100% !important;
  line-height: inherit !important;
  text-transform: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  cursor: pointer !important;
}

:focus {
  outline: none !important;
}

/* .using-tab :focus {outline:none!important; box-shadow:inset 0 0 0 2px #D86018!important;} */

.close {
  display: block !important;
  font-size: 60px !important;
  width: 1em !important;
  height: 1em !important;
  position: absolute !important;
  z-index: 10 !important;
  right: 0 !important;
  top: 0 !important;
  margin: 20px !important;
  background-size: 10em auto !important;
  background-position: -2em -0.33em !important;
}

/* Adam Dimech's Plant Breeder's Webfont */
/* http://www.adonline.id.au/fonts/plant-breeders-webfont */

@font-face {
  font-family: 'adonline-PBR';
  src: url('./assets/plant-breeders-webfont/adonline-pbr-webfont.woff2') format('woff2'), url('./assets/plant-breeders-webfont/adonline-pbr-webfont.woff') format('woff'), url('./assets/plant-breeders-webfont/adonline-pbr-webfont.ttf') format('truetype'), url('./assets/plant-breeders-webfont/adonline-pbr-webfont.svg#adonline-pbr') format('svg');
  font-display: swap;
}

.pbr {
  font-family: 'adonline-PBR' !important;
}

.btn__primary,
.btn__secondary,
.btn__tertiary,
input[type="submit"],
button[type="submit"] {
  cursor: pointer !important;
  word-break: normal !important;
  position: relative !important;
  z-index: 1 !important;
  overflow: hidden !important;
  display: inline-block !important;
  vertical-align: middle !important;
  width: auto !important;
  box-sizing: border-box !important;
  background-color: #d86018 !important;
  color: #ffffff !important;
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 1.4em !important;
  text-align: center !important;
  text-decoration: none !important;
  padding: 1em 2em !important;
  margin: 8px 5px !important;
  border: 1px solid transparent !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.btn__primary:hover,
.btn__secondary:hover,
.btn__tertiary:hover,
input[type="submit"]:hover,
button[type="submit"]:hover {
  color: #ffffff !important;
  background: #f96921 !important;
  text-decoration: none !important;
}

/* .using-tab .btn__primary:focus, .using-tab .btn__secondary:focus, .using-tab input[type="submit"]:focus, .using-tab button[type="submit"]:focus {box-shadow:inset 0 0 0 2px #487A7B!important;} */

.btn__secondary {
  background: none !important;
  color: #222c34 !important;
  border-color: #d86018 !important;
}

.btn__secondary:hover {
  text-decoration: none !important;
  background: rgba(216, 96, 24, 0.1) !important;
  color: #222c34 !important;
}

.btn__tertiary,
input[type="file"]::file-selector-button {
  background: #487a7b !important;
}

.btn__tertiary:hover,
input[type="file"]::file-selector-button:hover {
  background: #4e9696 !important;
}

.text-white .btn__secondary {
  color: #ffffff !important;
}

.text-white .btn__secondary:hover {
  color: #ffffff !important;
}

input[type="file"] {
  padding: 0 !important;
}

input[type="file"]::file-selector-button {
  background-color: #487a7b !important;
  color: #ffffff !important;
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 1.4em !important;
  padding: 1em 2em !important;
  margin-right: 10px !important;
}

input[type="file"]::-ms-browse {
  background-color: #487a7b !important;
  color: #ffffff !important;
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 1.4em !important;
  padding: 1em 2em !important;
  margin: 0 !important;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: #487a7b !important;
  color: #ffffff !important;
  font-family: aller, sans-serif !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 1.4em !important;
  padding: 1em 2em !important;
  margin-right: 10px !important;
}

.btn-save:before,
.btn-continue:after,
.btn-back:before,
.btn-calculator:before,
.btn-add:before {
  content: "" !important;
  display: inline-block !important;
  vertical-align: middle !important;
  font-size: 40px !important;
  width: 1em !important;
  height: 1em !important;
  margin: -0.55em 0.2em -0.45em -0.4em !important;
  background-size: 15em auto !important;
  background-position: -0.75em -13.25em !important;
}

.btn-save:before {
  background-position: -0.75em -13.25em !important;
}

.btn-continue:after {
  background-position: -3.25em -8.25em !important;
  margin: -0.55em -0.5em -0.45em 0.2em !important;
}

.btn-back:before {
  background-position: -0.75em -5.75em !important;
  margin-left: -0.5em !important;
}

.btn-calculator:before {
  background-position: -3.25em -10.75em !important;
  margin-left: -0.5em !important;
}

.btn-add:before {
  background-position: -3.25em -18.25em !important;
  margin-left: -0.5em !important;
}

/* -------- Modals ------------ */
.overlay {
  display: flex !important;
  align-items: center !important;
  box-sizing: border-box !important;
  overscroll-behavior: contain !important;
  position: fixed !important;
  min-width: 320px !important;
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  height: 100% !important;
  max-height: 0 !important;
  overflow: hidden !important;
  background: rgba(51, 63, 72, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  -webkit-overflow-scrolling: touch !important;
  z-index: 110 !important;
  transition: max-height 0s 0.5s, background 0.5s !important;
}

.overlay.open {
  max-height: 100% !important;
  overflow-y: scroll !important;
  background: rgba(51, 63, 72, 0.9) !important;
  transition: max-height 0s, background 0.5s !important;
}

.modal-box {
  position: relative !important;
  overflow: hidden !important;
  height: auto !important;
  max-width: 100% !important;
  padding: 40px !important;
  margin: auto !important;
  box-sizing: border-box !important;
  transform: translate3d(0, -40px, 0) !important;
  opacity: 0 !important;
  transition: transform 0.5s, opacity 0.5s !important;
  background: #ffffff !important;
  text-align: left !important;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3) !important;
}

.overlay.open .modal-box {
  transform: translate3d(0, 0, 0) !important;
  opacity: 1 !important;
}

.modal-large .modal-box {
  max-width: 1200px !important;
}

.modal-header {
  margin-right: 40px !important;
}

.modal-header * {
  margin-top: 0 !important;
}

.modal-box .fieldwrap-type-submit {
  display: flex !important;
  flex-wrap: wrap !important;
}

.modal-box .fieldwrap-type-submit>* {
  flex: 1 0 auto !important;
  margin-bottom: 0 !important;
}

.modal-box fieldset,
.modal-box fieldset:nth-of-type(odd) {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;
}

.modal-box fieldset:nth-of-type(odd) .fieldwrap {
  background: #fbfbfb !important;
}

.modal-box .total-wrap {
  padding: 0 0 0 16px !important;
  margin: 16px 0 !important;
  box-sizing: border-box !important;
}

.modal-box .total-wrap span {
  padding: 17px 0 !important;
}

.modal-box .total {
  font-size: 1.4em !important;
  line-height: 1 !important;
  padding: 0 16px !important;
  margin: 0 !important;
  flex: 1 0 auto !important;
}

/* -------- Login ------------*/
#login {
  height: 100% !important;
  background: #243444 url(images/bg-login.svg) repeat-y left center !important;
  background-size: 100% auto !important;
}

#login {
  position: relative !important;
  z-index: 1 !important;
  overflow: hidden !important;
  height: 100% !important;
  vertical-align: middle !important;
}

.login-inner {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  max-height: 100vh !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.login-inner .content-padding {
  margin: auto !important;
}

.login-header {
  max-width: 400px !important;
  padding-bottom: 32px !important;
  margin: 0 auto !important;
}

.login-header-logo {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 1920px !important;
  align-items: center !important;
  justify-content: flex-end !important;
  text-align: right !important;
  position: absolute !important;
  right: 75px !important;
  top: 0 !important;
  margin: 0 auto !important;
}

.login-header-logo a {
  display: inline-block !important;
  vertical-align: top !important;
  padding: 20px 2.2% !important;
  margin: 0 2% 0 0 !important;
  box-sizing: border-box !important;
}

.login-title {
  display: inline-block !important;
  vertical-align: bottom !important;
  font-size: 36px !important;
  border-left: 0.27em solid #d86018 !important;
  padding-left: 0.33em !important;
}

.login-box {
  position: relative !important;
  overflow: hidden !important;
  flex: 0 0 auto !important;
  width: 300px !important;
  margin: 0 auto auto auto !important;
  transition: height 0.5s !important;
}

.login-box .h4 {
  margin-top: 0 !important;
}

.login-form {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  max-height: 0 !important;
  box-sizing: border-box !important;
  opacity: 0 !important;
  overflow: hidden !important;
  transition: max-height 0s 0.5s, opacity 0.5s !important;
}

.login-form.form-open {
  position: relative !important;
  max-height: 9999px !important;
  opacity: 1 !important;
  transition: max-height 0.5s, opacity 0.5s !important;
}

.login-form .btn__secondary,
.login-form button[type="submit"] {
  width: 100% !important;
  margin: 0 0 16px 0 !important;
}

.btn-forgot {
  position: absolute !important;
  right: 0 !important;
  margin: 1px !important;
  width: 58px !important;
  height: 58px !important;
  font-weight: 700 !important;
  line-height: 58px !important;
  text-align: center !important;
  background: #e4ebeb !important;
  color: #a3bcbd !important;
  transition: background 0.3s, color 0.3s !important;
}

.btn-forgot:hover {
  text-decoration: none !important;
  background: #d1dede !important;
  color: #91afb0 !important;
}

.btn-forgot+input {
  padding-right: 60px !important;
}

.login-footer {
  align-self: flex-end !important;
  width: 100% !important;
  max-width: 1920px !important;
  box-sizing: border-box !important;
  text-align: right !important;
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
  padding: 30px 2.2% !important;
  margin: 0 auto !important;
}

.login-footer-logo a {
  display: inline-block !important;
}

.login-footer .copyright {
  font-size: 14px !important;
  color: #858c91 !important;
}

/* -------- Home ------------*/
.category-card {
  display: block !important;
  background: #1a1a1a !important;
  color: #d86018 !important;
  border-bottom: 10px solid currentColor !important;
  position: relative !important;
  overflow: hidden !important;
  background-size: cover !important;
  background-position: center right !important;
  font-size: 0 !important;
}

.category-card:before {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  z-index: 1 !important;
  left: -77% !important;
  top: -10% !important;
  width: 200% !important;
  padding: 0 0 55% 0 !important;
  background: rgba(0, 0, 0, 0.4) !important;
  transform: rotate(45deg) !important;
  opacity: 0 !important;
  transition: all 0.5s !important;
}

.category-card:after {
  content: "" !important;
  display: inline-block !important;
  vertical-align: middle !important;
  width: 0 !important;
  padding: 0 0 58% 0 !important;
}

.category-card:hover {
  text-decoration: none !important;
}

.category-card:hover:before {
  transform: translateX(15%) rotate(45deg) !important;
  opacity: 1 !important;
}

.cat-image {
  position: absolute !important;
  left: -3% !important;
  top: 0 !important;
  width: 103% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center right !important;
  transform: translateX(0) !important;
  transition: all 0.6s !important;
}

.cat-image:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: currentColor;
  mix-blend-mode: color;
  opacity: 0.5;
}

.cat-image:after {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 104% !important;
  height: 100% !important;
  background-image: url(images/cat-overlay.svg) !important;
  opacity: 0.5 !important;
  background-size: cover !important;
  background-position: center right !important;
  transform: translateX(0) !important;
  transition: all 0.6s !important;
}

.category-card:hover .cat-image {
  transform: translateX(2.5%) !important;
}

.category-card:hover .cat-image:after {
  transform: translateX(-4%) !important;
}

.cat-title {
  display: inline-block !important;
  vertical-align: middle !important;
  position: relative !important;
  z-index: 2 !important;
  padding: 14% 4% 14% 46% !important;
  color: #ffffff !important;
  font-size: 36px !important;
  line-height: 1 !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
}

.cat-time {
  display: block !important;
  padding: 0.53em 0.59em 0.53em 1.18em !important;
  background: #ffffff !important;
  font-size: 17px !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
  color: #d86018 !important;
  position: absolute !important;
  z-index: 2 !important;
  left: 0 !important;
  bottom: 0 !important;
  margin: 0 0 4% 0 !important;
}

.cat-time:after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  left: 100% !important;
  top: 0 !important;
  width: 2.46em !important;
  height: 100% !important;
  background: #ffffff !important;
  clip-path: polygon(0 0, 100% 0, 0 100%) !important;
}

.cat-infield {
  color: #333f48 !important;
}

.cat-contract {
  color: #487a7b !important;
}

.cat-groups {
  color: #0c2340 !important;
}

.cat-annual .cat-image {
  background-image: url(images/cat-annual.jpg) !important;
}

.cat-infield .cat-image {
  background-image: url(images/cat-infield.jpg) !important;
  border-color: #333f48 !important;
}

.cat-contract .cat-image {
  background-image: url(images/cat-contract.jpg) !important;
  border-color: #487a7b !important;
}

.cat-groups .cat-image {
  background-image: url(images/cat-groups.jpg) !important;
  border-color: #487a7b !important;
}

/* -------- Annual ------------ */
.sidebar-group {
  display: table !important;
  width: 100% !important;
}

.sidebar {
  display: table-cell !important;
  vertical-align: top !important;
  width: 220px !important;
  padding-right: 30px !important;
  box-sizing: border-box !important;
}

.steps {
  font-size: 0 !important;
  margin: 0 -5px !important;
}

.steps a {
  display: inline-block !important;
  vertical-align: top !important;
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #222c34 !important;
  text-align: center !important;
  background: #d6d9da !important;
  margin: 5px !important;
  transition: background 0.3s !important;
}

.steps a:hover {
  text-decoration: none !important;
  background: #ebeced !important;
}

.steps a.active {
  background: #d86018 !important;
  color: #ffffff !important;
}

.main {
  display: block;
  width: 100%;
}

.cost-counter label {
  font-size: 24px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.cost-counter label .small {
  line-height: 20px !important;
  text-transform: none !important;
}

.cost-counter input {
  font-size: 24px !important;
  line-height: 34px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.calc-field,
.label-inline>.calc-field {
  position: relative !important;
  width: 108px !important;
  max-width: 108px !important;
  flex: 0 0 108px !important;
}

.calc-field input {
  color: #ffffff !important;
  background: #487a7b !important;
  border-color: #487a7b !important;
}

.calc-field label,
.number label {
  display: none !important;
  position: absolute !important;
  right: 0 !important;
  bottom: 100% !important;
  margin-bottom: 10px !important;
  line-height: 1 !important;
  text-transform: uppercase !important;
}

.number label {
  margin-right: 60px !important;
}

.fieldwrap:first-child .number label,
.fieldwrap:first-child .calc-field label {
  display: block !important;
}

.columns2 .fieldwrap:nth-child(2) .number label,
.columns2 .fieldwrap:nth-child(2) .calc-field label {
  display: block !important;
}

fieldset:nth-of-type(odd) .calc-field label,
fieldset:nth-of-type(odd) .number label {
  margin-bottom: 50px !important;
}

.total-wrap {
  display: flex !important;
  background: #edf2f2 !important;
  border-left: 1px solid #a3bcbd !important;
  padding: 0 100px 0 40px !important;
  margin-right: -100px !important;
}

.total-wrap span {
  display: inline-block !important;
  flex: 1 1 1100px !important;
  padding: 40px 0 !important;
  margin-right: 16px !important;
  font-weight: 700 !important;
  color: #333f48 !important;
}

.total {
  display: flex !important;
  background: #487a7b !important;
  flex: 1 0 350px !important;
  padding: 40px 100px 40px 0 !important;
  margin: 0 -100px 0 auto !important;
  text-align: right !important;
}

.total span {
  background: #487a7b !important;
  border-color: #487a7b !important;
  color: #ffffff !important;
  padding: 0 0 0 40px !important;
}

.total span:before {
  content: "$" !important;
}

.total .calc-harv {
  font-weight: 300 !important;
}

.total .calc-total:after {
  content: "Total" !important;
  display: block !important;
  font-size: 14px !important;
}

.total .calc-harv:after {
  content: "Per Harv" !important;
  display: block !important;
  font-size: 14px !important;
}

/* -------- In-Season ------------ */
.field-group {
  border-left: 1px solid #a3bcbd !important;
  margin-bottom: 16px !important;
}

.field-group .fieldwrap:last-child {
  margin-bottom: 0 !important;
}

fieldset .btn-add {
  width: 100% !important;
  margin: 0 !important;
}

/* -------- Expand ------------ */
.expand>.content-1920>.content-padding {
  margin: 0 0 50px 0 !important;
}

.expand-header {
  position: relative !important;
}

.expand-header p {
  position: relative !important;
  padding: 1em 1em 1em 2.5em !important;
  margin: 0 !important;
  cursor: pointer !important;
  border-top: 1px solid #cfd8dc !important;
  border-width: 1px 0 !important;
  font-size: 24px !important;
  line-height: 1.2 !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
  letter-spacing: -0.02em !important;
}

.expand-header p:after {
  content: "" !important;
  cursor: pointer !important;
  display: block !important;
  position: absolute !important;
  z-index: 1 !important;
  left: 0 !important;
  top: 50% !important;
  font-size: 2.5em !important;
  width: 1em !important;
  height: 1em !important;
  border-radius: 50% !important;
  margin: -0.5em 0 !important;
  background-size: 10em auto !important;
  background-position: -2em -10.33em !important;
  transform: rotate(-90deg) !important;
  transition: transform 0.2s, background-color 0.2s !important;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53), ease !important;
}

.expand-open .expand-header p:after {
  transform: none !important;
  background-position: -0.33em -10.33em !important;
}

.expand-content {
  height: 0 !important;
  padding: 0 0 0 60px !important;
  overflow: hidden !important;
  position: relative !important;
  border-bottom: 1px solid #cfd8dc !important;
  transition: height 0.5s, padding 0.5s !important;
}

.expand-open .expand-content {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* -------- Footer ------------*/
#footer {
  display: table-row !important;
}

#footer .body-cell {
  background: #edf2f2 !important;
  height: 1px !important;
}

#footer .content-padding {
  display: table !important;
  width: 100% !important;
  margin: 2% 0 !important;
  box-sizing: border-box !important;
}

.footer-logo {
  display: table-cell !important;
  vertical-align: middle !important;
  padding: 0 20px 0 0 !important;
}

.copyright {
  display: table-cell !important;
  vertical-align: bottom !important;
  text-align: left !important;
  font-size: 14px !important;
  color: #858c91 !important;
}

.copyright strong {
  display: inline-block !important;
}

/* -------- Media Queries ------------*/
@media screen and (max-width: 1920px) {

  /* -------- Content ------------ */
  .bg-overlap:after {
    padding-bottom: 6.8% !important;
  }

  /* -------- Home ------------ */
  .cat-title {
    font-size: 1.875vw !important;
  }

  .cat-time {
    font-size: 0.885vw !important;
  }
}

@media screen and (max-width: 1800px) {

  /* -------- Forms ------------ */
  fieldset {
    padding-left: 30px !important;
  }

  fieldset:nth-of-type(odd),
  fieldset.bg-dark {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-right: 80px !important;
    margin-right: -80px !important;
  }

  fieldset fieldset,
  fieldset:nth-of-type(odd) fieldset {
    padding: 0 30px 0 0 !important;
    margin: 0 !important;
  }

  fieldset fieldset:nth-of-type(even) {
    padding: 0 0 30px 30px !important;
    margin: 0 !important;
  }

  fieldset fieldset:last-of-type {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  fieldset fieldset:nth-of-type(even):after {
    margin: 0 -80px 0 0 !important;
  }

  fieldset fieldset:nth-of-type(2):after {
    margin-top: -30px !important;
  }

  fieldset fieldset:last-of-type:after {
    margin-bottom: -30px !important;
  }

  /* -------- Header ------------ */
  #header .body-cell {
    height: 150px !important;
  }

  #header .content-padding {
    height: 150px !important;
  }

  .header-logo {
    width: 180px !important;
  }

  .header-title span {
    font-size: 30px !important;
  }

  /* -------- Content ------------ */
  .content-padding {
    padding: 0 80px !important;
    margin: 80px 0 !important;
  }

  .content-padding.margin-top-small {
    margin-top: 40px !important;
  }

  .content-padding.margin-bottom-small {
    margin-bottom: 40px !important;
  }

  .columns2,
  .columns3 {
    margin: 0 -30px !important;
  }

  .columns3 {
    margin: 0 -15px !important;
  }

  .columns2>div {
    border-width: 0 30px !important;
  }

  .columns3>div {
    border-width: 0 15px !important;
  }

  /* -------- Annual ------------*/
  .total-wrap {
    padding: 0 80px 0 30px !important;
    margin-right: -80px !important;
  }

  .total-wrap span {
    padding: 30px 0 !important;
  }

  .total {
    padding: 30px 80px 30px 30px !important;
    margin: 0 -80px 0 auto !important;
  }

  .total span {
    padding: 0 0 0 30px !important;
  }

  /* -------- Footer ------------*/
  .footer-logo {
    width: 180px !important;
  }
}

@media screen and (max-width: 1600px) {

  /* -------- Text ------------*/
  html,
  body {
    font-size: 15px !important;
  }

  h1,
  .h1 {
    font-size: 48px !important;
  }

  h2,
  .h2 {
    font-size: 32px !important;
  }

  h3,
  .h3,
  blockquote {
    font-size: 28px !important;
  }

  h4,
  .h4 {
    font-size: 21px !important;
  }

  h5,
  .h5 {
    font-size: 21px !important;
  }

  h6,
  .h6 {
    font-size: 15px !important;
  }

  p {
    font-size: 15px !important;
  }

  /* -------- Lists ------------*/
  .content ul li,
  .content ol li {
    font-size: 15px !important;
  }

  /* -------- Tables ------------ */
  th,
  td {
    font-size: 15px !important;
    padding: 12px 16px !important;
  }

  /* -------- Forms ------------*/
  fieldset:nth-of-type(odd),
  fieldset.bg-dark {
    padding-right: 60px !important;
    margin-right: -60px !important;
  }

  fieldset fieldset:nth-of-type(even):after {
    margin-right: -60px !important;
  }

  label {
    font-size: 15px !important;
    margin: 6px 0 !important;
  }

  label.label-ontop {
    font-size: 10px !important;
    line-height: 20px !important;
    padding: 0 12px !important;
  }

  label.label-ontop.label-full {
    font-size: 15px !important;
    line-height: 24px !important;
    padding: 12px 12px !important;
  }

  .label-ontop+input,
  .label-ontop+select,
  .label-ontop textarea {
    padding-top: 16px !important;
    padding-bottom: 8px !important;
  }

  fieldset label {
    padding: 12px 12px !important;
  }

  input,
  textarea,
  select,
  select,
  option {
    font-size: 15px !important;
    padding: 12px 12px !important;
  }

  select {
    padding-right: 40px !important;
    height: 50px !important;
  }

  .label-inline>input,
  .label-inline>select,
  .label-inline>div,
  .number-input {
    width: 240px !important;
    max-width: 240px !important;
    flex-basis: 240px !important;
  }

  .checkbox input[type="checkbox"],
  .radio input[type="radio"] {
    font-size: 30px !important;
  }

  .checkbox input[type="checkbox"]+label,
  .radio input[type="radio"]+label {
    min-height: 30px !important;
    padding: 2px 0 2px 40px !important;
  }

  .checkbox input[type="checkbox"]+label:before,
  .radio input[type="radio"]+label:before {
    font-size: 30px !important;
  }

  .toggle input[type="checkbox"] {
    font-size: 26px !important;
    margin: 12px 0 !important;
  }

  .toggle input[type="checkbox"]+label {
    display: block !important;
    position: relative !important;
    padding: 12px 60px 12px 0 !important;
    margin: 0 !important;
  }

  .toggle input[type="checkbox"]+label:before,
  .toggle input[type="checkbox"]+label:after {
    font-size: 26px !important;
    margin: 12px 0 !important;
  }

  .number {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .number button {
    font-size: 50px !important;
  }

  .number label {
    margin-right: 50px !important;
  }

  [data-pre]:before,
  [data-post]:after {
    left: 50px !important;
    font-size: 15px !important;
    width: 50px !important;
    height: 48px !important;
    line-height: 48px !important;
  }

  [data-post]:after {
    left: auto !important;
    right: 50px !important;
  }

  [data-pre] input {
    padding-left: 60px !important;
  }

  [data-post] input {
    padding-right: 60px !important;
  }

  .reset {
    padding-right: 50px !important;
  }

  .btn-reset {
    font-size: 50px !important;
  }

  /* -------- Header ------------ */
  #header .body-cell {
    height: 120px !important;
  }

  #header .content-padding {
    height: 120px !important;
  }

  .header-logo {
    width: 140px !important;
  }

  .header-title span {
    font-size: 26px !important;
  }

  .btn__header {
    font-size: 50px !important;
  }

  select.header-season {
    height: 50px !important;
    line-height: 50px !important;
    border-radius: 25px !important;
  }

  .profile__icon {
    font-size: 20px !important;
  }

  .profile__menu {
    width: 260px !important;
  }

  .profile__menu .profile__header {
    padding-left: 80px !important;
  }

  .profile__menu a {
    line-height: 21px !important;
    padding: 14px 20px !important;
  }

  /* -------- Content ------------ */
  .content-padding {
    padding: 0 60px !important;
    margin: 60px 0 !important;
  }

  .content-padding.margin-top-small {
    margin-top: 30px !important;
  }

  .content-padding.margin-bottom-small {
    margin-bottom: 30px !important;
  }

  .columns2,
  .columns3 {
    margin: 0 -15px !important;
  }

  .columns2>div {
    border-width: 0 15px !important;
  }

  /* -------- Modals ------------*/
  .modal-box {
    padding: 30px !important;
    max-width: 90% !important;
  }

  /* -------- Buttons ------------*/
  .btn__primary,
  .btn__secondary,
  .btn__tertiary,
  input[type="submit"],
  button[type="submit"] {
    font-size: 15px !important;
    line-height: 1.2 !important;
    padding: 1em 1.6em !important;
  }

  .close {
    font-size: 50px !important;
  }

  /* -------- Login ------------*/
  .btn-forgot {
    width: 48px !important;
    height: 48px !important;
    line-height: 48px !important;
  }

  /* -------- Annual ------------ */
  .sidebar {
    width: 180px !important;
    padding-right: 20px !important;
  }

  .steps {
    margin: 0 -3px !important;
  }

  .steps a {
    margin: 3px !important;
  }

  .sidebar p {
    font-size: 14px !important;
  }

  .cost-counter label {
    font-size: 20px !important;
    line-height: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .cost-counter input {
    font-size: 20px !important;
    line-height: 28px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .calc-group,
  .label-inline>.calc-group {
    width: 348px !important;
    max-width: 348px !important;
    flex-basis: 348px !important;
  }

  .total-wrap {
    padding-right: 60px !important;
    margin-right: -60px !important;
  }

  .total {
    flex: 1 0 350px !important;
    padding-right: 60px !important;
    margin-right: -60px !important;
  }

  /* -------- Footer ------------*/
  .footer-logo {
    width: 140px !important;
  }

  .copyright {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1440px) {

  /* -------- Forms ------------*/
  fieldset .columns2>div {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  /* -------- Annual ------------*/
  .columns2 .fieldwrap:nth-child(2) .number label,
  .columns2 .fieldwrap:nth-child(2) .calc-field label {
    display: none !important;
  }
}

@media screen and (min-width: 1301px) {

  /* -------- Content ------------ */
  .hidden-full {
    display: none !important;
  }
}

@media (min-width: 961px) and (max-width: 1300px) {

  /* -------- Content ------------ */
  .hidden-large {
    display: none !important;
  }
}

@media screen and (max-width: 1300px) {

  /* -------- Text ------------*/
  h1,
  .h1 {
    font-size: 40px !important;
  }

  h2,
  .h2 {
    font-size: 28px !important;
  }

  h3,
  .h3,
  blockquote {
    font-size: 24px !important;
  }

  h4,
  .h4 {
    font-size: 18px !important;
  }

  h5,
  .h5 {
    font-size: 18px !important;
  }

  /* -------- Forms ------------ */
  fieldset:nth-of-type(odd),
  fieldset.bg-dark {
    padding-right: 40px !important;
    margin-right: -40px !important;
  }

  fieldset fieldset:nth-of-type(even):after {
    margin-right: -40px !important;
  }

  /* -------- Header ------------ */
  #header .body-cell {
    height: 80px !important;
  }

  #header .content-padding {
    height: 80px !important;
  }

  .header-logo {
    width: 96px !important;
  }

  .header-title {
    padding: 10px 16px !important;
  }

  .header-title span {
    font-size: 18px !important;
  }

  .header-buttons {
    padding: 10px 16px !important;
  }

  /* -------- Content ------------ */
  .content-padding {
    padding: 0 40px !important;
    margin: 40px 0 !important;
  }

  .content-padding.margin-top-small {
    margin-top: 20px !important;
  }

  .content-padding.margin-bottom-small {
    margin-bottom: 20px !important;
  }

  /* -------- Login ------------*/
  #login {
    background-size: 1300px auto !important;
    background-position: center !important;
  }

  .login-header {
    max-width: 280px !important;
    padding-bottom: 16px !important;
  }

  .login-title {
    font-size: 24px !important;
  }

  .login-header-logo img {
    width: 120px !important;
  }

  .login-footer-logo img {
    width: 144px !important;
  }

  .login-box {
    width: 280px !important;
  }

  /* -------- Annual ------------ */
  .sidebar-group {
    display: block !important;
  }

  .sidebar {
    display: block !important;
    width: auto !important;
    padding: 0 !important;
    border-bottom: 1px solid #d6d9da !important;
  }

  .sidebar .h1 {
    float: left !important;
    margin: 0 !important;
  }

  .sidebar p {
    clear: both !important;
  }

  .steps {
    text-align: right !important;
    padding: 4px 0 !important;
  }

  .main {
    display: block !important;
  }

  .total-wrap {
    padding-right: 40px !important;
    margin-right: -40px !important;
  }

  .total {
    padding-right: 40px !important;
    margin-right: -40px !important;
  }

  /* -------- Expand ------------ */
  .expand-header p {
    font-size: 20px !important;
  }

  .expand-content {
    padding-left: 50px !important;
  }

  /* -------- Footer ------------*/
  .footer-logo {
    width: 100px !important;
  }
}

@media screen and (max-width: 1080px) {

  /* -------- Forms ------------*/
  fieldset {
    display: block !important;
  }

  fieldset fieldset,
  fieldset:nth-of-type(odd) fieldset {
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
    max-width: none !important;
  }

  fieldset fieldset:nth-of-type(even) {
    padding: 30px 0 30px 30px !important;
    margin: 30px 0 30px -30px !important;
  }

  fieldset fieldset:last-of-type {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  fieldset fieldset:nth-of-type(2):after {
    margin-top: 0 !important;
  }

  fieldset fieldset:last-of-type:after {
    margin-bottom: -30px !important;
  }
}

@media screen and (max-height: 600px) and (max-width: 1300px) {

  /* -------- Login ------------*/
  .login-inner .login-footer {
    width: 280px !important;
  }

  .login-footer-logo img {
    width: 120px !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {

  /* -------- Content ------------ */
  .hidden-medium {
    display: none !important;
  }

  .login-header-logo {
    right: 0px !important;
  }
}

@media screen and (max-width: 960px) {

  /* -------- Forms ------------*/
  fieldset {
    padding: 0 0 0 16px !important;
  }

  fieldset:nth-of-type(odd),
  fieldset.bg-dark {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-right: 16px !important;
    margin-right: -16px !important;
  }

  fieldset fieldset:nth-of-type(even):after {
    margin-right: -16px !important;
  }

  fieldset fieldset,
  fieldset:nth-of-type(odd) fieldset {
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
    max-width: none !important;
  }

  fieldset fieldset:nth-of-type(even) {
    padding: 16px 0 16px 16px !important;
    margin: 16px 0 16px -16px !important;
  }

  fieldset fieldset:last-of-type {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  fieldset fieldset:nth-of-type(2):after {
    margin-top: 0 !important;
  }

  fieldset fieldset:last-of-type:after {
    margin-bottom: -16px !important;
  }

  /* -------- Header ------------ */
  #header .body-cell {
    height: 60px !important;
  }

  #header .content-padding {
    height: 60px !important;
    background-position: 100px top !important;
  }

  .header-logo {
    width: 70px !important;
  }

  .header-title {
    padding: 0 0 0 12px !important;
    background: rgba(237, 242, 242, 0.9) !important;
  }

  .header-title span {
    font-size: 14px !important;
  }

  .header-buttons {
    padding: 0 4px !important;
    vertical-align: middle !important;
    background: rgba(237, 242, 242, 0.9) !important;
  }

  .btn__header {
    font-size: 40px !important;
    margin: 2px !important;
  }

  select.header-season {
    height: 40px !important;
    line-height: 40px !important;
    border-radius: 20px !important;
    padding: 0 10px 0 36px !important;
    margin: 2px !important;
  }

  .profile__icon {
    font-size: 16px !important;
    margin: 2px !important;
  }

  .profile__menu .profile__header {
    padding-left: 70px !important;
  }

  /* -------- Content ------------ */
  .content-padding {
    padding: 0 16px !important;
  }

  .columns2,
  .columns3 {
    display: block !important;
    max-width: 480px !important;
    margin: 0 auto !important;
  }

  .columns2>div,
  .columns3>div {
    display: block !important;
    width: auto !important;
    max-width: none !important;
    flex: none !important;
    border: 0 !important;
    margin: 0 0 30px 0 !important;
  }

  .columns3>div {
    width: auto !important;
    max-width: none !important;
    flex: none !important;
  }

  fieldset .columns2,
  fieldset .columns3 {
    max-width: none !important;
  }

  fieldset .columns-wrap {
    margin: 0 0 16px 0 !important;
  }

  fieldset .columns-wrap:last-child {
    margin: 0 !important;
  }

  fieldset .columns2>div,
  fieldset .columns3>div {
    margin: 0 0 16px 0 !important;
  }

  /* -------- Modals ------------ */
  .modal-box {
    padding: 30px 16px !important;
  }

  /* -------- Home ------------ */
  .cat-title {
    font-size: 32px !important;
  }

  .cat-time {
    font-size: 17px !important;
  }

  /* -------- Annual ------------*/
  .total-wrap {
    padding: 0 16px 0 16px !important;
    margin-right: -16px !important;
  }

  .total-wrap span {
    padding: 16px 0 !important;
  }

  .total {
    padding: 16px 16px 16px 16px !important;
    margin: 0 -16px 0 auto !important;
  }

  .total span {
    padding: 0 0 0 16px !important;
  }

  /* -------- Footer ------------*/
  .copyright {
    font-size: 10px !important;
  }

  .login-header-logo {
    right: 0px !important;
  }
}

@media screen and (max-width: 800px) {

  /* -------- Forms ------------*/
  .label-inline .number+select {
    width: 140px !important;
    max-width: 140px !important;
    flex-basis: 140px !important;
  }

  /* -------- Tables ------------*/
  td,
  th {
    font-size: 14px !important;
  }

  .harv-settings {
    display: block !important;
  }

  .harv-settings tbody {
    display: block !important;
  }

  .harv-settings tr {
    display: block !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .harv-settings th {
    display: none !important;
  }

  .harv-settings td {
    display: inline-block !important;
    flex: 1 1 auto !important;
  }

  .harv-settings td:first-child {
    display: block !important;
    flex-basis: 100% !important;
  }

  /* -------- Login ------------*/
  .login-header-logo {
    display: inline-block !important;
    vertical-align: bottom !important;
    position: relative !important;
    height: auto !important;
  }

  .login-header-logo a {
    max-width: 100% !important;
    padding: 20% 20% 16px 0 !important;
    margin: 0 !important;
  }

  .login-title {
    font-size: 24px !important;
  }

  .login-footer {
    position: relative !important;
    right: auto !important;
    bottom: auto !important;
    width: 280px !important;
    padding: 20px 16px !important;
    text-align: left !important;
    background: #364857 !important;
  }

  .login-footer-logo img {
    width: 120px !important;
  }

  .login-header-logo {
    right: 0px !important;
  }
}

@media screen and (max-width: 600px) {

  /* -------- Text ------------*/
  h1,
  .h1 {
    font-size: 36px !important;
  }

  h2,
  .h2 {
    font-size: 24px !important;
  }

  h3,
  .h3,
  blockquote {
    font-size: 21px !important;
  }

  /* -------- Forms ------------ */
  .fieldwrap.label-inline {
    flex-wrap: wrap !important;
    min-width: auto !important;
  }

  .label-inline label {
    flex: 0 0 100% !important;
  }

  .label-inline>input,
  .label-inline>select,
  .label-inline>div {
    width: 163px !important;
    max-width: none !important;
    flex: 1 0 163px !important;
  }

  .fieldwrap-type-submit input,
  .fieldwrap-type-submit button,
  .fieldwrap-type-submit a {
    display: block !important;
    width: 100% !important;
    margin: 16px 0 !important;
  }

  /* -------- Header ------------ */
  .btn-menu {
    display: inline-block !important;
  }

  .header-menu {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s;
  }

  .header-menu.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.3s, visibility 0s, transform 0.3s;
  }

  /* -------- Content ------------*/
  .hidden-small {
    display: none !important;
  }

  /* -------- Modals ------------ */
  .modal-header {
    margin-right: 30px !important;
  }

  /* -------- Buttons ------------ */
  .close {
    font-size: 40px !important;
    margin: 10px !important;
  }

  /* -------- Annual ------------ */
  .calc-field label,
  .number label {
    display: block !important;
    font-size: 10px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    bottom: 0 !important;
    margin-bottom: 4px !important;
    color: #999fa3 !important;
  }

  fieldset:nth-of-type(2n + 1) .calc-field label,
  fieldset:nth-of-type(2n + 1) .number label {
    margin-bottom: 4px !important;
  }

  .calc-field label {
    color: #a3bcbd !important;
  }

  .columns2 .fieldwrap:nth-child(2) .number label,
  .columns2 .fieldwrap:nth-child(2) .calc-field label {
    display: block !important;
  }

  .total-wrap {
    flex-wrap: wrap !important;
  }

  .total-wrap span {
    flex: 1 1 auto !important;
  }

  .total {
    flex: 1 1 auto !important;
    flex-wrap: wrap !important;
  }

  .total span {
    margin: 10px 0 !important;
  }

  /* -------- Expand ------------ */
  .expand-header p {
    font-size: 18px !important;
  }

  .expand-content {
    padding-left: 0 !important;
  }

  .login-header-logo {
    right: 0px !important;
  }
}

@media screen and (max-width: 512px) {

  /* -------- Forms ------------*/
  .label-inline .number+select {
    width: 100% !important;
    max-width: none !important;
    flex-basis: 100% !important;
  }

  /* -------- Home ------------ */
  .cat-title {
    font-size: 6.25vw !important;
  }

  .cat-time {
    font-size: 3.32vw !important;
  }
}

@media screen and (max-width: 400px) {

  /* -------- Header ------------*/
  #header .content-padding {
    padding: 0 !important;
  }

  /* -------- Annual ------------*/
  .fieldwrap.label-inline {
    display: block !important;
  }

  .label-inline>input,
  .label-inline>select,
  .label-inline>div,
  .number-input {
    width: 100% !important;
    max-width: none !important;
  }

  .calc-field,
  .label-inline>.calc-field {
    width: auto !important;
    max-width: none !important;
  }

  .calc-field label {
    padding-right: 60px !important;
  }

  .calc-field input {
    padding-right: 60px !important;
  }
}

@media screen and (max-width: 360px) {

  /* -------- Header ------------*/
  .header-logo {
    width: 60px !important;
  }

  .header-title {
    padding: 0 0 0 8px !important;
  }

  .header-title span {
    font-size: 11px !important;
  }

  /* -------- Footer ------------*/
  #footer .content-padding {
    display: block !important;
    padding: 10px 16px !important;
  }

  .footer-logo {
    display: block !important;
    width: auto !important;
    text-align: center !important;
  }

  .footer-logo a {
    display: inline-block !important;
    width: 100px !important;
  }

  .copyright {
    display: block !important;
    text-align: center !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-height: 600px) {

  /* -------- Login ------------*/
  .login-header-logo {
    display: inline-block !important;
    vertical-align: bottom !important;
    position: relative !important;
    width: 120px !important;
    height: auto !important;
  }

  .login-header-logo a {
    max-width: 100% !important;
    padding: 20% 20% 16px 0 !important;
    margin: 0 !important;
  }

  .login-title {
    font-size: 24px !important;
  }

  .login-footer {
    position: relative !important;
    right: auto !important;
    bottom: auto !important;
    width: 300px !important;
    padding: 20px 16px !important;
    text-align: left !important;
    background: #364857 !important;
  }

  .login-footer-logo img {
    width: 120px !important;
  }
}

@media print {

  /* -------- Page ------------*/
  @page {
    margin: 10mm !important;
  }

  html,
  body {
    height: auto !important;
  }

  * {
    print-color-adjust: exact !important;
    -webkit-print-color-adjustt: exact !important;
  }

  /* -------- Text ------------*/
  h1,
  .h1 {
    font-size: 24pt !important;
  }

  h2,
  .h2 {
    font-size: 18pt !important;
  }

  h3,
  .h3 {
    font-size: 16pt !important;
  }

  h4,
  .h4 {
    font-size: 12pt !important;
  }

  h5,
  .h5 {
    font-size: 12pt !important;
  }

  h6,
  .h6 {
    font-size: 10pt !important;
  }

  p {
    font-size: 9pt !important;
  }

  blockquote {
    padding: 0 5mm !important;
    font-size: 12pt !important;
  }

  ul li,
  ol li {
    font-size: 9pt !important;
  }

  /* -------- Forms ------------*/
  label {
    padding: 0 !important;
    font-size: 9pt !important;
  }

  input,
  textarea,
  select,
  option {
    font-size: 9pt !important;
    line-height: 1.6 !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    height: auto !important;
    min-height: auto !important;
  }

  .number button {
    display: none !important;
  }

  /* -------- Tables ------------ */
  table {
    font-size: 9pt !important;
  }

  /* -------- Content ------------*/
  .content-padding {
    padding: 0 !important;
  }

  .print-hide {
    display: none !important;
  }
}

.btn-left-space {
  margin-left: 10px !important;
}